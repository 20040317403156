import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function RecordingsTable() {
    const [recordings, setRecordings] = useState([]);
    const [selectedRecording, setSelectedRecording] = useState(null);
    const [searchDate, setSearchDate] = useState(null);
    const [filteredRecordings, setFilteredRecordings] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editData, setEditData] = useState({ originalTranscript: '', summarizedText: '' });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [recordingToDelete, setRecordingToDelete] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); // State for the search query

        // Pagination states
        const [currentPage, setCurrentPage] = useState(1);
        const recordsPerPage = 5; // You can adjust this value

     // Calculate the current records to display
     const indexOfLastRecord = currentPage * recordsPerPage;
     const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
     const currentRecords = filteredRecordings.slice(indexOfFirstRecord, indexOfLastRecord);

      // Change page handler
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Total number of pages
    const pageCount = Math.ceil(filteredRecordings.length / recordsPerPage);

    // Render page numbers
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        const authToken = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`, // Include the token here
        };
        const apiUrl = process.env.REACT_APP_API_URL;

        fetch(`${apiUrl}/recordings/`, { headers })
            .then(response => response.json())
            .then(data => {
                setRecordings(data);
                setFilteredRecordings(data);
            })
            .catch(error => console.error('Error fetching recordings:', error));
    }, []);

    useEffect(() => {
        let filtered = recordings;
    
        if (searchDate) {
            const dateStr = searchDate.toLocaleDateString();
            filtered = filtered.filter(rec => new Date(rec.date).toLocaleDateString() === dateStr);
        }
    
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            filtered = filtered.filter(
                rec => rec.originalTranscript.toLowerCase().includes(lowercasedQuery) ||
                       rec.summarizedText.toLowerCase().includes(lowercasedQuery)
            );
        }
    
        setFilteredRecordings(filtered);
    }, [searchQuery, searchDate, recordings]);

    function DeleteConfirmationModal({ isOpen, onClose, onConfirm }) {
        if (!isOpen) return null;
    
        return (
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)' }}>
                    <p>Are you sure you want to delete this recording?</p>
                    <button
                        onClick={onConfirm}
                        className="mt-4 bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-2 rounded mr-2 text-sm"
                    >
                        Yes, Delete
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-2 px-2 rounded text-sm"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }

    const handleEdit = (recording) => {
        setIsEditing(true);
        setEditData({
            originalTranscript: recording.originalTranscript,
            summarizedText: recording.summarizedText,
            _id: recording._id,
            filename: recording.filename
        });
        setSelectedRecording(null); // Hide detail view
    };

    const copyTextToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(editData.summarizedText);
          alert('Text copied to clipboard!');
        } catch (err) {
          alert('Failed to copy text: ', err);
        }
      };

    const handleEditChange = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    const saveEdit = async () => {
        try {
            // Preparing the data to be sent in the PUT request
            const { _id, originalTranscript, summarizedText, tags } = editData;
    
            // Retrieve the JWT token from localStorage
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;

    
            // Make the PUT request to update the data on the server
            const response = await fetch(`${apiUrl}/recordings/${_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    // Include the Authorization header with the token
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    originalTranscript,
                    summarizedText,
                    tags
                })
            });
    
            // Check if the request was successful
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const updatedData = await response.json();
    
            // Update the local state to reflect the changes
            const updatedRecordings = recordings.map(rec => 
                rec._id === _id ? { ...rec, ...updatedData.data } : rec
            );
            setRecordings(updatedRecordings);
            setFilteredRecordings(updatedRecordings);
    
            // Exit editing mode
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating the recording:', error);
            // Handle the error state here, e.g., show an error message to the user
        }
    };
    

    // const showFullText = (recording) => {
    //     setSelectedRecording(recording);
    // };

    const backToList = () => {
        setSelectedRecording(null);
    };

    const truncateText = (text) => {
        if (text && text.length > 250) {
            return text.substring(0, 250) + '...';
        }
        return text || '';
    };

    const confirmDelete = (recordingId) => {
        setShowDeleteModal(true);
        setRecordingToDelete(recordingId);
    };

    const deleteRecording = async (id) => {
        try {
            // Retrieve the JWT token from localStorage
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;

            const response = await fetch(`${apiUrl}/recordings/${id}`, {
                method: 'DELETE',
                headers: {
                    // Include the Authorization header with the token
                    'Authorization': `Bearer ${token}`,
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            // Update the state to remove the deleted recording
            const updatedRecordings = recordings.filter(recording => recording._id !== id);
            setRecordings(updatedRecordings);
            setFilteredRecordings(updatedRecordings);
    
            // Close the modal after deletion
            setShowDeleteModal(false);
            setRecordingToDelete(null);
        } catch (error) {
            console.error('Error deleting the recording:', error);
            setShowDeleteModal(false);
            setRecordingToDelete(null);
        }
    };

  return (
    <div>
            {isEditing ? (
                <div className="mt-6 p-4">
                    {/* Audio Player */}
                    {editData.filename && (
                            <div className="mt-4">
                                <label className="block mb-2">Recording:</label>
                                <audio controls>
                                <source src={`${process.env.REACT_APP_FILES_URL}/${editData.filename}`} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                        )}
                    <label className="mt-4 block">Original Transcript:</label>
                    <textarea
                        name="originalTranscript"
                        value={editData.originalTranscript}
                        onChange={handleEditChange}
                        readOnly
                        className="w-full h-40 mb-4 bg-gray-200 p-2 rounded focus:outline-none"
                    />
                    <label className="block">AI Text:</label>
                    <textarea
                        name="summarizedText"
                        value={editData.summarizedText}
                        onChange={handleEditChange}
                        className="w-full h-40 mb-4 p-2 rounded focus:outline-none"
                    />
                    <button
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none mr-2"
                        onClick={copyTextToClipboard}
                        >
                        Copy
                        </button>
                    
                    <button
                        className="bg-emerald-500 text-white py-2 px-4 rounded hover:bg-emerald-600 focus:outline-none mr-2"
                        onClick={saveEdit}
                    >
                        Save
                    </button>
                    <button
                        className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 focus:outline-none"
                        onClick={() => setIsEditing(false)}
                    >
                        Cancel
                    </button>
                </div>
            ) : selectedRecording ? (
                <div className="mt-6 max-w-screen-md mx-auto p-4">
                    <button className="bg-teal-700 text-white py-2 px-4 rounded hover:bg-teal-800 focus:outline-none" onClick={backToList}>Back</button>
                    <p className="mt-4 p-0">Date: {new Date(selectedRecording.date).toLocaleString()}</p>
                    <p>Original Transcript: {selectedRecording.originalTranscript}</p>
                    <p>AI Text: {selectedRecording.summarizedText}</p>
                    
                </div>
        ) : (
            <>
                <div className="flex justify-center items-center mt-5">
                    <input
                        type="text"
                        placeholder="Search transcripts..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-3/5 p-2 ml-4 border rounded focus:outline-none focus:ring-2 focus:ring-teal-300"
                    />
                    <ReactDatePicker
                        selected={searchDate}
                        onChange={(date) => setSearchDate(date)}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Search by date..."
                        className="w-4/5 ml-5 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-teal-300 date-picker"
                    />
                </div>

                <div className="mt-6 ml-4 mr-4">
                {currentRecords.length > 0 ? (

                    <div className="flex justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-6xl">
                        {currentRecords.map((recording, index) => (
                        <div key={recording._id} className={`p-4 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} border border-gray-200 rounded-lg`}>
                            <div className="flex flex-col">
                            <div className="mb-2">
                                <span className="date-block font-semibold text-sm text-gray-900">{new Date(recording.date).toLocaleDateString()}</span><br />
                                <span className="time-block text-sm text-gray-600">{new Date(recording.date).toLocaleTimeString()}</span>
                            </div>
                            <div className="mb-2">
                                <button 
                                style={{ background: 'none', border: 'none', padding: 0, color: 'inherit', cursor: 'pointer' }}
                                className="text-sm text-left text-gray-900 hover:text-blue-500"
                                onClick={() => handleEdit(recording)}>
                                {truncateText(recording.summarizedText)}
                                </button>
                            </div>
                            <div className="flex space-x-2">
                                <button 
                                className="bg-emerald-500 text-white py-1 px-3 rounded hover:bg-emerald-600 focus:outline-none"
                                onClick={() => handleEdit(recording)}>
                                View
                                </button>
                                <button 
                                className="bg-rose-600 text-white py-1 px-3 rounded hover:bg-rose-700 focus:outline-none"
                                onClick={() => confirmDelete(recording._id)}>
                                Delete
                                </button>
                            </div>
                            </div>
                        </div>
                        ))}
                    </div>
                    </div>


                    ) : (
                        <div className="text-center py-6">
                            <p>No recordings found.</p>
                        </div>
                    )}
                    <nav className="flex justify-center items-center p-4">
                        {/* Left arrow for previous page, disabled if on the first page */}
                        <a
                            href="#!"
                            onClick={(e) => {
                            e.preventDefault();
                            if (currentPage > 1) paginate(currentPage - 1);
                            }}
                            className={`mx-2 px-4 py-2 rounded cursor-pointer ${currentPage > 1 ? 'bg-gray-400 hover:bg-gray-600 text-slate-50' : 'bg-gray-200 text-slate-50 cursor-not-allowed'}`}
                        >
                            &#8592;
                        </a>

                        {/* Current Page Number */}
                        <span className="text-green-700 px-4 py-2">{currentPage}</span>

                        {/* Right arrow for next page, disabled if on the last page */}
                        <a
                            href="#!"
                            onClick={(e) => {
                            e.preventDefault();
                            if (currentPage < pageNumbers.length) paginate(currentPage + 1);
                            }}
                            className={`mx-2 px-4 py-2 rounded cursor-pointer ${currentPage < pageNumbers.length ? 'bg-gray-400 hover:bg-gray-600 text-slate-50' : 'bg-gray-200 text-slate-50 cursor-not-allowed'}`}
                        >
                            &#8594;
                        </a>
                        </nav>

                </div>
            </>
        )}
        <DeleteConfirmationModal 
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={() => deleteRecording(recordingToDelete)}
        />
    </div>
);

    
    
}

export default RecordingsTable;
