// In src/App.js
import React, { useState, useEffect } from 'react';
import AudioRecorder from './audioRecorder';
import RecordingsTable from './components/RecordingsTable';
import './index.css'; // or import './App.css';
import localForage from 'localforage';


function App() {

    const narrators = ['David Attenborough', 'Maya Angelou', 'Ricky Gervais', 'H.P. Lovecraft'];
    const persons = ['First Person', 'Second Person', 'Third Person'];
    const tones = ['Scientific', 'Formal', 'Narrative', 'Casual' ];
    const languages = ['English', 'Italian', 'Russian', 'Spanish'];
    const roles = ['Summarize', 'Grammar', 'Email', 'Feedback'];

    const [transcription, setTranscription] = useState("");
    const [activeTab, setActiveTab] = useState('record');
    const [isLoading, setIsLoading] = useState(false);
    const [showCompletionMessage, setShowCompletionMessage] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [mode, setMode] = useState('login'); // Toggle between 'login' and 'signup'
    const [email, setEmail] = useState('');
    const [isSignupMode, setIsSignupMode] = useState(false); // Toggle between login and signup mode

    const [showSettings, setShowSettings] = useState(false); // State to control settings visibility
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [hasFileToRetry, setHasFileToRetry] = useState(false); // State to track retry status


    // Toggle function to switch between modes
        const toggleMode = () => {
            setIsSignupMode(!isSignupMode);
            setLoginErrorMessage(''); // Clear any existing error messages when toggling
        };

    // const handleSettingsClick = () => {
    //     setShowSettings(!showSettings); // Toggle the settings visibility
    // };

    const [settings, setSettings] = useState({
        narrator: 'Ricky Gervais',
        person: 'First Person',
        tone: 'Casual',
        language: 'English',
        role: 'Summarize'
    });

    const handleSettingsChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        // Fetch user settings and authentication status when the component mounts
        const initializeApp = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                setLoggedIn(true);
                fetchSettings(token); // Fetch settings using the token
            }

            const storedUsername = localStorage.getItem('username');
            if (storedUsername) {
                setUsername(storedUsername);
            }
        };

        initializeApp();
    }, []);

    const submitSettings = async () => {
        // API call to update settings on backend
        const token = localStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await fetch(`${apiUrl}/user/settings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    // Include authorization headers if necessary
                },
                body: JSON.stringify(settings)
            });

            if (!response.ok) throw new Error('Network response was not ok');
            // Handle successful response
        } catch (error) {
            console.error('Error updating settings:', error);
            // Handle errors
        }
        setShowConfirmation(true); // Show confirmation message
  
        setTimeout(() => setShowConfirmation(false), 3000); // Hide message after 3 seconds


        // setShowSettings(false); // Close settings after submission
    };

    const fetchSettings = async (token) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await fetch(`${apiUrl}/user/settings`, {
                method: 'GET', // Assuming the method to fetch settings is GET
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) throw new Error('Failed to fetch settings');
            const userSettings = await response.json();
            setSettings(userSettings.settings); // Assuming the API returns settings in the correct format
        } catch (error) {
            console.error('Error fetching user settings:', error);
            // Handle error (e.g., by showing an error message or using default settings)
        }
    };

    const handleLogin = async (event) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        event.preventDefault();
        try {
            const response = await fetch(`${apiUrl}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });
            if (!response.ok) throw new Error('Login failed');
            const { token } = await response.json();
            localStorage.setItem('token', token); // Save token to localStorage
            localStorage.setItem('username', username);
            setLoggedIn(true);
        } catch (error) {
            setLoginErrorMessage('Username or password is incorrect');
            // console.error('Login error:', error);
        }
    };

    const handleLogout = () => {
        setSuccessMessage(false); // Clear any existing success messages
        localStorage.removeItem('token'); // Remove token from storage
        setLoggedIn(false); // Update loggedIn state
    };

    const handleSignup = async (event) => {
        event.preventDefault();
    
        // Basic sanitation function to remove script tags or unwanted characters
        const sanitizeInput = (input) => {
            const tagBody = '(?:[^"\'>]|"[^"]*"|\'[^\']*\')*';
            const tagOrComment = new RegExp(
                '<(?:' +
                '!--(?:(?:-*[^->])*--+|-?)' +
                '|script\\b' + tagBody + '>[\\s\\S]*?</script\\s*' +
                '|style\\b' + tagBody + '>[\\s\\S]*?</style\\s*' +
                '|/?[a-z]' +
                tagBody +
                ')>',
                'gi');
            let oldInput;
            do {
                oldInput = input;
                input = input.replace(tagOrComment, '');
            } while (input !== oldInput);
            return input.trim();
        };
    
        // Basic input validation
        const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        const validatePassword = (password) => password.length >= 8; // Example: Check password length
        const validateUsername = (username) => /^[a-zA-Z0-9_]+$/.test(username); // Example: Allow letters, numbers, and underscores
    
        // Sanitize inputs
        const sanitizedUsername = sanitizeInput(username);
        const sanitizedEmail = sanitizeInput(email);
        const sanitizedPassword = sanitizeInput(password);
    
        // Validate inputs
        if (!validateEmail(sanitizedEmail)) {
            setLoginErrorMessage('Please enter a valid email address.');
            return;
        }
        if (!validatePassword(sanitizedPassword)) {
            setLoginErrorMessage('Password must be at least 8 characters long.');
            return;
        }
        if (!validateUsername(sanitizedUsername)) {
            setLoginErrorMessage('Username should only contain letters, numbers, and underscores.');
            return;
        }
    
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username: sanitizedUsername, email: sanitizedEmail, password: sanitizedPassword }),
            });
    
            if (!response.ok) {
                const errorResponse = await response.json();
                throw new Error(errorResponse.message || 'Signup failed');
            }
            // Set a success message
            setSuccessMessage('Signup was successful, you can now log in.');
            setLoginErrorMessage(''); // Clear any existing error messages
            setUsername('');
            setPassword('');
            setEmail('');
            setIsSignupMode(false); // Switch to login mode
        } catch (error) {
            setLoginErrorMessage(error.message);
            setSuccessMessage(''); // Clear any existing success messages
        }
    };
    

        // Modify your form's onSubmit to conditionally handle login or signup
const handleSubmit = (event) => {
    if (isSignupMode) {
        handleSignup(event); // You need to define this based on the previous example
    } else {
        handleLogin(event);
    }
};

    const saveAudioToFileStorage = async (audioBlob) => {
        try {
            await localForage.setItem('savedAudio', audioBlob);
            console.log('Audio saved for later');
        } catch (error) {
            console.error('Error saving audio:', error);
        }
    };

    const getAudioFromStorage = async () => {
        try {
            const audioBlob = await localForage.getItem('savedAudio');
            if (audioBlob) {
                console.log('Retrieved saved audio');
                return audioBlob;
            } else {
                console.log('No audio file saved');
                return null;
            }
        } catch (error) {
            console.error('Error retrieving audio:', error);
            return null;
        }
    };

    const retryUpload = async () => {
        setIsLoading(true);
        setErrorMessage(''); // Clear existing error message
    
        try {
            const audioBlob = await getAudioFromStorage();
            if (!audioBlob) {
                throw new Error('No audio file available for retry.');
            }
    
            await handleRecordingComplete(audioBlob, true); // Pass true to indicate a retry attempt
        } catch (error) {
            setErrorMessage(error.message || 'Retry failed. Please try again.');
            setHasFileToRetry(true); // Keep the retry option available
        } finally {
            setIsLoading(false);
        }
    };
        
    

 const handleRecordingComplete = async (audioBlob, isRetry = false) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    setIsLoading(true); // Start loading
    setShowCompletionMessage(false); // Reset completion message
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio.wav');

    const token = localStorage.getItem('token');

    try {
        const response = await fetch(`${apiUrl}/transcribe`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // Include the Authorization header with the token
            },
            body: formData
        });

        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setTranscription(data.data.transcript);
        setShowCompletionMessage(true); // Show completion message

        if (isRetry) {
            setHasFileToRetry(false); // Reset only if this was a retry attempt
        }
        setTimeout(() => setShowCompletionMessage(false), 3000); // Hide message after 3 seconds

    } catch (error) {
        console.error('Error during API request:', error);
        setErrorMessage('Something went wrong, the note was not saved.'); // Set a user-friendly error message

        if (!isRetry) { // Only save for retry if this wasn't already a retry attempt
            await saveAudioToFileStorage(audioBlob);
            setHasFileToRetry(true);
        }
    } finally {
        setIsLoading(false); // Stop loading
    }
};

return (
    <div>
        {!loggedIn ? (
            <form className="max-w-lg mx-auto text-center mt-4" onSubmit={handleSubmit}>
                <img src="/miniremi-logo.png" alt="Miniremi Logo" style={{ width: '30px', margin: '0 auto' }} />
                <h1 className="text-3xl text-gray-800 my-4">Login to MINIREMI</h1>
                {loginErrorMessage && <div className="text-red-500 mb-2">{loginErrorMessage}</div>}
                {successMessage && <div className="text-green-500 mb-2">{successMessage}</div>}
        
                <div>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => {
                    setUsername(e.target.value);
                    setLoginErrorMessage(''); // Clear login error message
                    }}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                />
                </div>
                <div>
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                    setPassword(e.target.value);
                    setLoginErrorMessage(''); // Clear login error message
                    }}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                />
                </div>
                <div>
                <button type="submit" className="w-full bg-emerald-500 text-white py-2 px-4 rounded hover:bg-emerald-800">
                    Login
                </button>
                </div>
            </form>
        ) : (
            <>  
            
                <div className='mx-1 pt-4 text-center'>
                    <img src="/miniremi-logo.png" alt="Miniremi Logo" style={{ width: '30px', margin:'0 auto'}} />
                    <h1 className="text-3xl text-gray-800 my-4">MINIREMI</h1>
                    <nav className="mt-6">
                    <a 
                            className={`text-xs sm:text-base cursor-pointer mx-1 px-4 py-2 rounded ${activeTab === 'record' ? 'bg-emerald-700 text-white' : 'text-emerald-700 hover:bg-emerald-100'}`}
                            onClick={() => setActiveTab('record')}>
                            RECORD
                        </a>
                        <a 
                            className={`text-xs sm:text-base cursor-pointer mx-1 px-4 py-2 rounded ${activeTab === 'view' ? 'bg-emerald-700 text-white' : 'text-emerald-700 hover:bg-emerald-100'}`}
                            onClick={() => setActiveTab('view')}>
                            NOTES
                        </a>
                        <a 
                            className={`text-xs sm:text-base cursor-pointer mx-1 px-4 py-2 rounded ${activeTab === 'settings' ? 'bg-emerald-700 text-white' : 'text-emerald-700 hover:bg-emerald-100'}`}
                            onClick={() => setActiveTab('settings')}>
                            SETTINGS
                        </a>
                        <a 
                            className="text-xs sm:text-base cursor-pointer mx-1 px-4 py-2 rounded text-emerald-700 hover:bg-emerald-100"
                            onClick={handleLogout}>
                            LOGOUT
                        </a>
                    </nav>
                </div>

                <div>
                {activeTab === 'record' && (
                        <div style={{ margin:'80px 20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <AudioRecorder 
                            onRecordingComplete={handleRecordingComplete}
                            transcriptionProp={transcription} // Pass the transcription text as prop
                            />
                            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                            {isLoading && (
                                 <>
                                    <div style={{ display: 'inline-block' }}>
                                        <div className="spinner"></div>
                                    </div>
                                    <p>Transcription in progress...</p>
                                </>
                            )}
                                {!isLoading && showCompletionMessage && <p className='font-medium'>Transcribed and summarized, you'll find your note in NOTES</p>}
                                {!isLoading && !transcription && errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
                                {!isLoading && hasFileToRetry && (
                                    <button 
                                        onClick={retryUpload}
                                        className="bg-emerald-500 text-white py-2 px-4 rounded hover:bg-emerald-800 mt-2">
                                        Retry
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    {activeTab === 'view' && <RecordingsTable />}
                    {activeTab === 'settings' && (
                        <div className="settings-tab-content mt-4 m-8">
                            <div className="my-4">
                            <div className='my-4 mt-8'>
                                        <span className="text-gray-400">Username: {username}</span> 
                                    </div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Role
                                </label>
                                <select 
                                    className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                    name="role"
                                    value={settings.role} 
                                    onChange={handleSettingsChange}>
                                    {roles.map(role => (
                                        <option key={role} value={role}>{role}</option>
                                    ))}
                                    {username === 'oliluca2' && (
                                        <option value="Feedback">Feedback</option>
                                    )}
                                </select>

                            </div>

                            {/* Language field shown for all roles */}
                            <div className="my-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Language:
                                </label>
                                <select 
                                    className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                    name="language"
                                    value={settings.language} 
                                    onChange={handleSettingsChange}>
                                    {languages.map(language => (
                                        <option key={language} value={language}>{language}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Conditional fields for Grammar or Email */}
                            {/* {settings.role === 'Grammar' && (
                                <div className="text-green-500 mt-2">
                                    Please select your preferences for grammar checks.
                                </div>
                            )} */}

                            {settings.role === 'Email' && (
                                
                                <div className="my-4">
                                     <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Tone:
                                        </label>
                                        <select 
                                            className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                            name="tone" 
                                            value={settings.tone} 
                                            onChange={handleSettingsChange}>
                                            {tones.map(tone => (
                                                <option key={tone} value={tone}>{tone}</option>
                                            ))}
                                        </select>
                                    <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                                        Paste here an email example:
                                    </label>
                                    <textarea
                                        className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        name="emailexample"
                                        value={settings.emailexample}
                                        onChange={handleSettingsChange}
                                        rows="4"
                                    ></textarea>
                                </div>
                            )}

                            {/* Show other fields based on role selection */}
                            {settings.role !== 'Grammar' && settings.role !== 'Email' && (
                                <>
                                    <div className="my-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Narrator:
                                        </label>
                                        <select 
                                            className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                            name="narrator" 
                                            value={settings.narrator} 
                                            onChange={handleSettingsChange}>
                                            {narrators.map(narrator => (
                                                <option key={narrator} value={narrator}>{narrator}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="my-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Person:
                                        </label>
                                        <select 
                                            className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                            name="person" 
                                            value={settings.person} 
                                            onChange={handleSettingsChange}>
                                            {persons.map(person => (
                                                <option key={person} value={person}>{person}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="my-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Tone:
                                        </label>
                                        <select 
                                            className="block w-full bg-white border border-gray-300 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                            name="tone" 
                                            value={settings.tone} 
                                            onChange={handleSettingsChange}>
                                            {tones.map(tone => (
                                                <option key={tone} value={tone}>{tone}</option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            )}

                            {showConfirmation && (
                                <div className="text-green-500 mt-2">
                                    Settings saved successfully!
                                </div>
                            )}
                            <div className="flex justify-between">
                                <button 
                                    className="bg-teal-700 text-white py-2 px-4 rounded cursor-pointer hover:bg-teal-800 focus:outline-none transition-colors"
                                    onClick={submitSettings}>
                                    Save Settings
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </>
        )}
        <footer className="mt-4 text-center py-4 border-t-2 border-zinc-200">
            {/* <a className="text-grey-200 hover:text-grey-500 mt-4 text-s">
                Terms of service    
            </a> */}
            {/* <a className="text-grey-300 hover:text-grey-700 ml-4" onClick={() => 
                Contact
            </a> */}
            <div className="text-gray-600 mt-4">
                MINIREMI ver.0.25
                <br />
                Support: mail@ottoquattro.com
            </div>
        </footer>
    </div>
);


}

export default App;
