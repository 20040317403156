import React, { useState, useEffect } from 'react';

function AudioRecorder({ onRecordingComplete }) {
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [timer, setTimer] = useState(0);
    const [transcription, setTranscription] = useState('');

    useEffect(() => {
        let interval;

        if (isRecording) {
            interval = setInterval(() => {
                setTimer((prevTime) => {
                    if (prevTime >= 1200) {
                        stopRecording();
                        return 1200;
                    }
                    return prevTime + 1;
                });
            }, 1000);
        } else if (!isRecording && timer !== 0) {
            clearInterval(interval);
            setTimer(0);
        }
        return () => clearInterval(interval);
    }, [isRecording, timer]);

    const startRecording = async () => {
        if (isRecording || !navigator.mediaDevices.getUserMedia) {
            console.error("Audio recording is already in progress or not supported.");
            return;
        }

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        let audioChunks = [];

        recorder.ondataavailable = (e) => audioChunks.push(e.data);
        recorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks);
            const transcriptionResult = await onRecordingComplete(audioBlob);
            setTranscription(transcriptionResult);
        };

        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
    };

    const stopRecording = () => {
        mediaRecorder.stop();
        setIsRecording(false);
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 20971520) { // 20MB in bytes
                alert('File is too large. The maximum file size is 20MB.');
                return;
            }
            const transcriptionResult = await onRecordingComplete(file);
            setTranscription(transcriptionResult);
        }
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <p className='mt-2 mb-8'>Record an audio note, or upload any audio file.</p>
            <div    
                className={`w-24 h-24 rounded-full bg-red-500 flex items-center justify-center cursor-pointer shadow-xl mb-5 text-white ${isRecording ? 'bg-green-500 rounded-lg' : ''}`}
                onClick={isRecording ? stopRecording : startRecording}
            >
                {isRecording ? '⏹️' : '🔴'}
            </div>
            {isRecording && <p className="text-gray-800 text-sm font-light mb-4">Recording Time: {formatTime(timer)}</p>}
            <label className="mt-4 w-34 pt-6 flex flex-col items-center px-4 py-4 bg-white rounded-lg shadow-sm tracking-wide cursor-pointer hover:bg-emerald-700 hover:text-white">    
                <svg className="w-4 h-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M16.88 2.88A3 3 0 0014.12.88L5 .87a3 3 0 00-3 3V16a3 3 0 003 3h10a3 3 0 003-3V5.75a3 3 0 00-.12-.87zM10 15a5 5 0 110-10 5 5 0 010 10zm3-5a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                <span className="mt-2 text-sm">Upload a file</span>
                <input type='file' className="hidden" accept="audio/*" onChange={handleFileUpload} />
            </label>
        </div>
    );
}

export default AudioRecorder;
